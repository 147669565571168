
import modal from "@/components/common/modalManage";
import { Lookup } from "@/components/pagebuilder/models/common.model";
import agent, { masterServiceBaseURL } from "@/services/agent";
import authService from "@/services/authService";
import toasterService from "@/services/toasterService";
import { L10n, setCulture } from "@syncfusion/ej2-base";
import { DropDownListComponent } from "@syncfusion/ej2-vue-dropdowns";
import {
  CommandColumn,
  ColumnDirective as EColumn,
  ColumnsDirective as EColumns,
  GridComponent as EjsGrid,
  Group,
  Page,
  Resize,
  Search,
  Sort,
  Toolbar,
} from "@syncfusion/ej2-vue-grids";
import { TreeViewComponent } from "@syncfusion/ej2-vue-navigations";
import { DialogUtility } from "@syncfusion/ej2-vue-popups";
import { defineComponent } from "vue";
import { Subscription } from "../Subscription/Subscription.model";
import SubscriptionCommonComponent from "../Subscription/SubscriptionCommonComponent.vue";
import { API_KEY, DBUserType } from "./constant";
import {
  DatabaseUser,
  PropertyUser,
  SubscriptionGetResponse,
  UserGetResponse,
  UserResponse,
} from "./DatabaseUser.model";

let _userData: PropertyUser = {} as PropertyUser;
let _userForm: DatabaseUser = {} as DatabaseUser;
let _subscriptionData: Subscription[] = [];
let _dbUserType: Lookup<number>[] = [];
let Confirmation: any = undefined;

export default defineComponent({
  components: {
    "ejs-grid": EjsGrid,
    "e-columns": EColumns,
    "e-column": EColumn,
    "ejs-dropdownlist": DropDownListComponent,
    SubscriptionCommonComponent,
    "ejs-treeview": TreeViewComponent,
  },
  provide: {
    grid: [Toolbar, Resize, Search, CommandColumn, Page, Sort, Group],
  },
  created() {
    agent.setBaseUrl(masterServiceBaseURL);

    this.gridLocalize();
  },
  data() {
    return {
      headers: {},
      listSubscription: _subscriptionData,
      listUserType: _dbUserType,
      fieldsRole: { text: "name", value: "id" },
      fieldsSubscription: { text: "organizationName", value: "id" },
      fieldsUserType: { text: "name", value: "name" },
      data: _userData,
      form: _userForm,
      isUpdate: false,
      confirmTitle: "",
      confirmIsDelete: false,
      selectedState: null,
      subscriptionId: "",
      toolbar: ["Search"],
      searchOptions: {
        fields: ["FirstName", "LastName", "Email"],
        operator: "contains",
        ignoreCase: true,
      },
      pageSettings: {
        pageSizes: [10, 20, 50, 100],
        pageCount: 5,
        pageSize: 50,
        skip: 0,
        take: 50,
      },
      commands: [
        {
          type: "Edit",
          buttonOption: { cssClass: "e-flat", iconCss: "e-edit e-icons" },
        },
        {
          type: "Delete",
          buttonOption: { cssClass: "e-flat", iconCss: "e-delete e-icons" },
        },
      ],
      locale: "",
      treeViewData: [
        { id: "all", text: "Staging Tables", hasChild: true },
        {
          id: "stg_account",
          pid: "all",
          text: "Stg_Account",
        },
      ],
      scopeTreeSelectedNodes: Array<string>(),
      showPassword: false,
    };
  },
  mounted() {
    this.dataStateChange(
      this.initState(this.pageSettings.skip, this.pageSettings.take)
    );
    this.loadSubscriptions();
    this.loadDbUserType();
    this.loadTreeViewTables();
  },
  methods: {
    initState(skip: number = 0, take: number): any {
      let state = {
        skip: skip,
        take: take,
      };
      return state;
    },
    dataStateChange(state: any) {
      this.pageSettings.skip = state.skip;
      this.pageSettings.take = state.take;
      this.gridLocalize();
      this.loadUser(state);
    },
    async loadUser(state: any) {
      if (state.action) {
        delete state.action;
      }

      var subscriptionId = this.subscriptionId;
      const gridUser: any = this.$refs.gridUser;
      if (gridUser) gridUser.hideSpinner();

      const response = await agent.post<UserGetResponse>(API_KEY.GET_USER, {
        ...state,
        subscriptionId,
      });

      if (response && response.result) {
        this.data = response.result;

        this.data.result.forEach((item: any) => {
          item.showPassword = false;
          item.dummyPassword = "********";
        });
      }
    },
    loadTreeViewTables() {
      const parentNode = { id: "all", text: "Staging Tables", hasChild: true };
      const entities = [
        "Account",
        "CostCenter",
        "Company",
        "Project",
        "Customer",
        "Invoice",
        "Employee",
        "FinancialYear",
        "ActualRevenue",
      ];

      const childNodes = entities.map((entity) => ({
        id: `stg_${entity.toLowerCase()}`,
        pid: "all",
        text: `Stg_${entity}`,
      }));

      this.treeViewData = [parentNode, ...childNodes];
    },
    onUpsert(event: any) {
      event.preventDefault();

      if (this.isUpdate) {
        this.onEdit();
      } else {
        this.onSave();
      }
    },
    async onSave() {
      const { separatedScopes, separatedEntities } =
        this.prepareScopeAndEntities();

      if (
        this.form.userType == DBUserType.StagingEditor &&
        !separatedEntities
      ) {
        toasterService.warn("Please select at least one table.");
        return;
      }

      this.form.allowedScopes = separatedEntities;

      let post = { ...this.form };
      post.createdBy = authService.getUserEmail();

      const response = await agent.post<UserResponse>(API_KEY.POST, post);
      if (response && response.result) {
        toasterService.success(this.$t("msgSave"));
        this.loadUser(
          this.initState(this.pageSettings.skip, this.pageSettings.take)
        );
        this.resetUser();
        this.close();
      }
    },
    async onEdit() {
      const { separatedScopes, separatedEntities } =
        this.prepareScopeAndEntities();

      if (
        this.form.userType == DBUserType.StagingEditor &&
        !separatedEntities
      ) {
        toasterService.warn("Please select at least one table.");
        return;
      }

      this.form.allowedScopes = separatedEntities;

      let put = { ...this.form };
      put.modifiedBy = authService.getUserEmail();

      const response = await agent.put<UserResponse>(API_KEY.PUT, put);
      if (response && response.result) {
        toasterService.success(this.$t("msgUpdate"));
        this.loadUser(
          this.initState(this.pageSettings.skip, this.pageSettings.take)
        );
        this.resetUser();
        this.close();
      }

      this.resetUser();
    },
    async onDelete(id: number) {
      const response = await agent.delete<UserResponse>(API_KEY.DELETE(id));
      if (response.result) {
        toasterService.success(this.$t("msgDelete"));
        this.loadUser(
          this.initState(this.pageSettings.skip, this.pageSettings.take)
        );
        this.resetUser();
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    resetUser(isNew: boolean = false) {
      var resetValue = {
        subscriptionId: "",
        organizationName: "",
        dbUserId: "",
        dbUserPassword: "",
        allowedIPs: "",
        allowedScopes: "",
        userType: "",
      } as DatabaseUser;

      this.form = { ...resetValue };

      // Need clear the selected cache of dropdown
      setTimeout(() => {
        const ddcustomerInstance: any = this.$refs.customerdd;

        const ddusertypeInstance: any = this.$refs.dbUserdd;

        if (isNew) {
          ddcustomerInstance?.clear();

          ddusertypeInstance?.clear();
        }
      }, 1);

      this.isUpdate = false;

      console.log("user form", this.form);
    },
    async loadSubscriptions() {
      const response = await agent.post<SubscriptionGetResponse>(
        API_KEY.GET_SUBSCRIPTION,
        {}
      );
      if (response && response.result) {
        this.listSubscription = [...response.result.result];
      }
    },
    async loadDbUserType() {
      const response = await agent.get<any>(API_KEY.GET_USERTYPE, {});
      if (response && response.result) {
        this.listUserType = [...response.result];
      }
    },
    onSelectCustomer(event: any) {
      if (event && event.itemData) {
        const id = event.itemData.id;
        // this.loadRoles(id);
      }
    },

    onChangeUserSubscription(event: any) {
      //   this.loadRoles(event.target.value);
    },
    onSubscriptionChange(subscriptionId: string) {
      this.subscriptionId = subscriptionId;

      if (subscriptionId.trim().toLowerCase().indexOf("all") > -1) {
        this.subscriptionId = "";
      }

      this.loadUser(
        this.initState(this.pageSettings.skip, this.pageSettings.take)
      );
    },
    gridLocalize() {
      this.locale = "en-grid";
      if (this.$i18n.locale == "se") {
        setTimeout(() => {
          import(`@/assets/sv.json`).then((module) => {
            const localText = module.default;
            this.locale = "sv";
            setCulture("sv");
            L10n.load(localText);
          });
        });
      }
    },
    commandClick: function (args: {
      commandColumn: any;
      rowData: DatabaseUser;
    }) {
      this.isUpdate = false;
      const app = this;
      if (args) {
        switch (args.commandColumn.type) {
          case "Delete":
            this.form.id = args.rowData.id;
            Confirmation = DialogUtility.confirm({
              title: this.$t("deleteConfiramtion"),
              content: this.$t("msgSureWantToDelete"),
              okButton: {
                text: this.$t("ok"),
                click: async function () {
                  Confirmation.hide();
                  app.onDelete(app.form.id);
                },
              },
              cancelButton: { text: this.$t("cancel") },
              showCloseIcon: true,
              closeOnEscape: true,
              zIndex: 10000,
              animationSettings: { effect: "Zoom" },
            });
            break;
          case "Edit":
            this.onEditInit(args.rowData);
            break;
          default:
            break;
        }
      }
    },
    async onEditInit(form: DatabaseUser) {
      this.resetUser();
      this.form = form;

      this.scopeTreeSelectedNodes = this.form.allowedScopes.split(",");
      this.isUpdate = true;
      this.prepareCompanyModalEditView();
      modal.Open("userModal");
    },
    prepareCompanyModalEditView() {
      const existingEntities = this.form.allowedScopes?.split(",") ?? [];
      this.treeViewData = this.treeViewData.map((node) => {
        if (node.hasChild) {
          const childNodes = this.treeViewData.filter(
            (childNode) => childNode.pid === node.id
          );
          const allChildrenChecked = childNodes.every((childNode) =>
            existingEntities.includes(childNode.id)
          );
          return { ...node, isChecked: allChildrenChecked };
        } else {
          return { ...node, isChecked: existingEntities.includes(node.id) };
        }
      });
    },
    isHost() {
      return authService.isHost();
    },
    open(isNew: boolean = false) {
      this.resetUser(isNew);
      modal.Open("userModal");
    },
    close() {
      this.resetUser();
      modal.Close("userModal");
    },
    getSelectedNodes(): Array<string> {
      const treeViewRef = this.$refs.treeView as InstanceType<
        typeof TreeViewComponent
      >;
      if (treeViewRef) {
        const selectedNodes = treeViewRef.getAllCheckedNodes();
        return selectedNodes;
      }
      return [];
    },
    getAllScopes() {
      return this.treeViewData
        .filter((node) => node.hasChild)
        .map((node) => node.id);
    },
    prepareScopeAndEntities() {
      const scopeList = this.getAllScopes();
      const selectedNodes = this.getSelectedNodes();

      const selectedEntities =
        selectedNodes.filter((node) => !scopeList.includes(node)) ?? [];
      const selectedScopes =
        selectedEntities.map(
          (entity) => this.treeViewData.find((node) => node.id === entity)?.pid
        ) ?? [];

      //
      const separatedScopes = [...new Set(selectedScopes)].join(",");
      const separatedEntities = selectedEntities.join(",");

      return { separatedScopes, separatedEntities };
    },
    copyToClipboard(errorMessage: string) {
      navigator.clipboard.writeText(errorMessage);

      toasterService.success(this.$t("msgCopied"));
    },
  },
});
