export const API_KEY = {
  GET_USER: "v1/DatabaseUser/getdbuser",
  GET_USERTYPE: "v1/databaseuser/listdbusertypes",
  GET_SUBSCRIPTION: "v1/subscription/getsubscription",
  POST: "v1/DatabaseUser",
  PUT: "v1/DatabaseUser",
  DELETE: (id: number) => `v1/DatabaseUser/${id}`,
};

export enum DBUserType {
  ReadOnly = "ReadOnly",
  StagingEditor = "StagingEditor",
}
