
import { GridComponent as EjsGrid, ColumnsDirective as EColumns, ColumnDirective as EColumn, Resize, Toolbar, Search, CommandColumn, Page, Group, Sort }
  from '@syncfusion/ej2-vue-grids';
import { defineComponent } from 'vue';
import modal from '@/components/common/modalManage';
import agent, { fortnoxServiceBaseURL, masterServiceBaseURL } from '@/services/agent';
import toasterService from '@/services/toasterService';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { DatePickerComponent } from '@syncfusion/ej2-vue-calendars';
import { Company, CompanyGetResponse, CompanyResponse, Property, SyncResponse } from './Company.model';
import preLoader from '@/services/preLoader';
import { getDateFormat, hasFortnox } from '@/shared/app.util';
import { useUserStore } from '@/store/useUserStore';
import { TreeViewComponent } from '@syncfusion/ej2-vue-navigations';
import { DialogUtility } from '@syncfusion/ej2-vue-popups';
import moment from 'moment';

let _companyData: Property = {} as Property;
let _companyForm: Company = {} as Company;
let Confirmation: any;

export default defineComponent({
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  components: {
    "ejs-grid": EjsGrid,
    "e-columns": EColumns,
    "e-column": EColumn,
    "ejs-treeview": TreeViewComponent,
    DatePickerComponent
  },
  provide: {
    grid: [Toolbar, Resize, Search, CommandColumn, Page, Sort, Group]
  },
  created() {
    agent.setBaseUrl(hasFortnox() ? fortnoxServiceBaseURL : masterServiceBaseURL);

    this.gridLocalize();
  },
  data() {
    return {
      data: _companyData,
      form: _companyForm,
      isUpdate: false,
      isTableLoad: false,
      selectedState: null,
      searchOptions: { fields: ['Name', 'CreationTime', 'RefreshTokenExpireDate', 'LastSyncDate'], operator: 'contains', ignoreCase: true },
      toolbar: ['Search'],
      pageSettings: {
        pageSizes: [10, 20, 50, 100],
        pageSize: 50,
        pageCount: 5,
        skip: 0,
        take: 50
      },
      commands: [
        { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
        { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } },
        { type: 'Force Fortnox Sync', buttonOption: { cssClass: 'e-flat force-sync', iconCss: 'e-repeat e-icons' } },
        { type: 'Fortnox Activation', buttonOption: { cssClass: 'e-flat', iconCss: 'e-plus e-icons' } },
      ],
      locale: '',
      forceSync: {
        date: null,
        format: 'yyyy-MM-dd',
        today: new Date(),
        companyId: 0,
        type: 'full',
        subscriptionId: '',
      },
      defaultTreeViewOptions: [
        "archive",
        "settings",
        "costcenter",
        "invoice",
        "customer",
        "bookkeeping",
        "project",
        "article",
        "payment",
        "connectfile",
        "supplier",
        "supplierinvoice",
        "Employee"
      ],
      treeViewData: [
        //{ id: 'companyinformation', text: 'Company Information', hasChild: true },
        //{ id: 'CompanySetting', pid: 'companyinformation', text: 'Company Information' },
        { id: 'archive', text: 'Archive (Any)', hasChild: true },
        { id: 'DownloadArchiveFiles', pid: 'archive', text: 'Download Archive Files' },

        // { id: 'inbox', text: 'Inbox (Any)', hasChild: true },
        // { id: 'DownloadInboxFiles', pid: 'inbox', text: 'Download Inbox Files' },

        { id: 'settings', text: 'Settings (Any)', hasChild: true },
        { id: 'Unit', pid: 'settings', text: 'Units' },
        { id: 'CompanySetting', pid: 'settings', text: 'Company Settings' },
        { id: 'LockedPeriod', pid: 'settings', text: 'Locked Period' },

        { id: 'costcenter', text: 'Cost Center', hasChild: true },
        { id: 'CostCenter', pid: 'costcenter', text: 'Cost Center' },

        { id: 'invoice', text: 'Invoice (Order or Kundfaktura)', hasChild: true },
        { id: 'ContractAccrualHead', pid: 'invoice', text: 'Contract Accruals' },
        { id: 'ContractTemplate', pid: 'invoice', text: 'Contract Templates' },
        { id: 'Contract', pid: 'invoice', text: 'Contracts' },
        { id: 'InvoiceAccrual', pid: 'invoice', text: 'Invoice Accruals' },
        { id: 'InvoiceHead', pid: 'invoice', text: 'Invoices' },

        { id: 'customer', text: 'Customer (Order or Kundfaktura)', hasChild: true },
        { id: 'Customer', pid: 'customer', text: 'Customers' },

        { id: 'bookkeeping', text: 'Bookkeeping (Bokföring or Kundfaktura)', hasChild: true },
        { id: 'VoucherHead', pid: 'bookkeeping', text: 'Vouchers' },
        { id: 'AccountList', pid: 'bookkeeping', text: 'Accounts' },
        { id: 'AccountChart', pid: 'bookkeeping', text: 'Account Charts' },
        { id: 'FinancialYear', pid: 'bookkeeping', text: 'Financial Years' },

        { id: 'project', text: 'Project (Bokföring or Order or Kundfaktura)', hasChild: true },
        { id: 'Project', pid: 'project', text: 'Projects' },

        { id: 'article', text: 'Article (Order or Kundfaktura)', hasChild: true },
        { id: 'Article', pid: 'article', text: 'Article' },

        { id: 'price', text: 'Price (Order or Kundfaktura)', hasChild: true },
        { id: 'PriceList', pid: 'price', text: 'Price Lists' },
        { id: 'Prices', pid: 'price', text: 'Prices' },

        { id: 'payment', text: 'Payment (Bokföring or Order or Kundfaktura)', hasChild: true },
        { id: 'InvoicePayment', pid: 'payment', text: 'Invoice Payments' },
        { id: 'SupplierInvoicePayment', pid: 'payment', text: 'Supplier Invoice Payments' },


        { id: 'connectfile', text: 'Connect File', hasChild: true },
        { id: 'ArticleFileConnection', pid: 'connectfile', text: 'Article File Connections' },
        { id: 'SupplierFileConnection', pid: 'connectfile', text: 'Supplier Invoice File Connections' },

        { id: 'supplier', text: 'Supplier (Bokföring)', hasChild: true },
        { id: 'Supplier', pid: 'supplier', text: 'Suppliers' },

        { id: 'supplierinvoice', text: 'Supplier Invoice (Bokföring)', hasChild: true },
        { id: 'SupplierInvoice', pid: 'supplierinvoice', text: 'Supplier Invoices' },
        { id: 'SupplierInvoiceExternalUrlConnection', pid: 'supplierinvoice', text: 'Supplier Invoice External URL Connections' },
        { id: 'SupplierInvoiceAccrual', pid: 'supplierinvoice', text: 'Supplier Invoice Accruals' },

        { id: 'order', text: 'Order (Order)', hasChild: true },
        { id: 'Order', pid: 'order', text: 'Orders' },

        { id: 'salary', text: 'Salary (Lön)', hasChild: true },
        { id: 'AbsenceTransaction', pid: 'salary', text: 'Absence transactions' },
        { id: 'AttendanceTransaction', pid: 'salary', text: 'Attendance transactions' },
        { id: 'Employee', pid: 'salary', text: 'Employees' },
        { id: 'Expense', pid: 'salary', text: 'Expenses' },
        { id: 'SalaryTransaction', pid: 'salary', text: 'Salary transactions' },
        { id: 'ScheduleTime', pid: 'salary', text: 'Schedule times' },

        { id: 'assets', text: 'Assets (Anläggningsregister)', hasChild: true },
        { id: 'Asset', pid: 'assets', text: 'Assets' },

        { id: 'currency', text: 'Currency (Bokföring or Order or Kundfaktura)', hasChild: true },
        { id: 'Currency', pid: 'currency', text: 'Currencies' },

        { id: 'offer', text: 'Offer (Order)', hasChild: true },
        { id: 'Offer', pid: 'offer', text: 'Offers' },
      ],
      scopeTreeSelectedNodes: Array<string>(),
    }
  },
  mounted() {
    preLoader.hide();
    if (location.search) {
      let params = this.split(location.search);
      agent.get<CompanyGetResponse>(`v1/fortnoxactivation/activatefortnox`, params).then((response) => {
        if (response.result) {
          this.$router.replace('/company');
          toasterService.success(this.$t("msgSave"));
          this.loadCompany(this.initState(this.pageSettings.skip, this.pageSettings.take));
        }
      });
    } else {
      this.dataStateChange(this.initState(this.pageSettings.skip, this.pageSettings.take));
    }
  },
  methods: {
    initState(skip: number = 0, take: number): any {
      let state = {
        skip: skip,
        take: take
      };
      return state;
    },
    split(url: string) {
      let regex = /[?&]([^=#]+)=([^&#]*)/g;
      let params = [];
      let match;

      while ((match = regex.exec(url))) {
        const paramName = decodeURIComponent(match[1]);

        const paramValue = decodeURIComponent(match[2]);

        params.push({ paramName: paramName, value: paramValue });
      }

      let payload = {
        sid: params.find((x) => x.paramName.indexOf("sid") > -1)!.value,
        code: params.find((x) => x.paramName.indexOf("code") > -1)!.value,
        state: params.find((x) => x.paramName.indexOf("state") > -1)!.value,
      };

      return payload;
    },
    dataStateChange(state: any) {
      this.pageSettings.skip = state.skip;
      this.pageSettings.take = state.take;
      this.gridLocalize();
      this.loadCompany(state);
    },
    loadCompany(state: any) {
      if (state.action) {
        delete state.action;
      }

      const gridCompany: any = this.$refs.gridCompany;
      if (gridCompany) {
        gridCompany.hideSpinner();
      }
      agent
        .post<CompanyGetResponse>(`v1/company/getcompany`, { ...state })
        .then((response) => {
          if (response.result) {
            this.data = response.result;
          }
        })
        .catch(() => { });
    },
    onUpsert(event: any) {
      event.preventDefault();

      if (this.isUpdate) {
        this.onEdit();
      } else {
        this.onSave();
      }
    },

    getSelectedNodes(): Array<string> {
      const treeViewRef = this.$refs.treeView as InstanceType<typeof TreeViewComponent>;
      if (treeViewRef) {
        const selectedNodes = treeViewRef.getAllCheckedNodes();
        return selectedNodes;
      }
      return [];
    },
    getAllScopes() {
      return this.treeViewData.filter(node => node.hasChild).map(node => node.id);
    },
    prepareScopeAndEntities() {
      const scopeList = this.getAllScopes();
      const selectedNodes = this.getSelectedNodes();

      const selectedEntities = selectedNodes.filter(node => !scopeList.includes(node)) ?? [];
      const selectedScopes = selectedEntities.map(entity => this.treeViewData.find(node => node.id === entity)?.pid) ?? [];

      // 
      const spaceSeparatedScopes = [...new Set(selectedScopes)].join(' ');
      const spaceSeparatedEntities = selectedEntities.join(' ');

      return { spaceSeparatedScopes, spaceSeparatedEntities };
    },

    onSave() {
      const { spaceSeparatedScopes, spaceSeparatedEntities } = this.prepareScopeAndEntities();

      if (!spaceSeparatedScopes || !spaceSeparatedEntities) {
        toasterService.warn('Please select scopes or entities');
        return;
      }

      let post = {
        ...this.form,
        scope: spaceSeparatedScopes,
        entitiesSelectedForSync: spaceSeparatedEntities,
        isScopeChangeApplied: false
      };

      agent.post<CompanyResponse>(`v1/company`, post).then((response) => {
        if (response.result) {
          toasterService.success(this.$t("msgSave"));
          this.loadCompany(this.initState(this.pageSettings.skip, this.pageSettings.take));
          this.resetCompany();
          this.close();
        }
      }).catch(() => { });
    },
    onEdit() {
      const { spaceSeparatedScopes, spaceSeparatedEntities } = this.prepareScopeAndEntities();

      // Check if scope is changed, then only mark token need to regenerate
      // false -> token needs to be regenerated with updated scopes
      // true -> token doesn't need to be regenerated
      const isScopeChanged = this.checkScopeChanged(this.form.scope, spaceSeparatedScopes);
      this.form.isScopeChangeApplied = isScopeChanged ? false : true;

      let put = { ...this.form, scope: spaceSeparatedScopes, entitiesSelectedForSync: spaceSeparatedEntities };

      agent.put<CompanyResponse>(`v1/company`, put).then((response) => {
        if (response.result) {
          toasterService.success(this.$t("msgUpdate"));
          this.loadCompany(this.initState(this.pageSettings.skip, this.pageSettings.take));
          this.resetCompany();
          this.close();
        }
      }).catch(() => { });
    },
    onDelete() {
      let put = { ...this.form };

      agent.delete<CompanyResponse>(`v1/company/${put.id}`, {}).then((response) => {
        if (response.result) {
          toasterService.success(this.$t("msgDelete"));
          this.loadCompany(this.initState(this.pageSettings.skip, this.pageSettings.take));
          this.resetCompany();
        }
      }).catch(() => { });
    },
    resetCompany() {
      this.form = {} as Company;
      this.isUpdate = false;
    },
    gridLocalize() {
      this.locale = 'en-grid';
      if (this.$i18n.locale == 'se') {
        setTimeout(() => {
          import(`@/assets/sv.json`).then(module => {
            const localText = module.default;
            this.locale = 'sv';
            setCulture('sv');
            L10n.load(localText);
          });
        });
      }
    },
    commandClick: function (args: { commandColumn: any, rowData: Company }) {
      this.isUpdate = false;
      const app = this;
      if (args) {
        switch (args.commandColumn.type) {
          case 'Delete':
            this.form.id = args.rowData.id;
            Confirmation = DialogUtility.confirm({
              title: this.$t("deleteConfiramtion"),
              content: this.$t("msgSureAllDataWithCompany"),
              okButton: {
                text: this.$t("ok"),
                click: async function () {
                  Confirmation.hide();
                  app.onDelete();
                },
              },
              cancelButton: { text: this.$t("cancel") },
              showCloseIcon: true,
              closeOnEscape: true,
              zIndex: 10000,
              animationSettings: { effect: "Zoom" },
            });
            break;
          case 'Edit':
            this.form = args.rowData;
            this.scopeTreeSelectedNodes = args.rowData.scope.split(' ');
            this.isUpdate = true;
            this.prepareCompanyModalEditView();
            modal.Open('companyModal');
            break;
          case 'Force Fortnox Sync':
            this.forceSync.companyId = args.rowData.id;
            modal.Open('force-sync-modal');
            break;
          case 'Fortnox Activation':
            agent.get<SyncResponse>(`v1/FortnoxActivation/GetFortnoxActivationToken/${args.rowData.id}`).then((response) => {
              if (response.result) {
                window.location.href = response.result.data;
                this.$router.push({ path: this.$route.path, query: { sid: 'sid' } })
              }
            }).catch(() => { });
            break;
          default: break;
        }
      }
    },
    prepareCompanyModalEditView() {
      const existingEntities = this.form.entitiesSelectedForSync?.split(' ') ?? [];
      this.treeViewData = this.treeViewData.map(node => {
        if (node.hasChild) {
          const childNodes = this.treeViewData.filter(childNode => childNode.pid === node.id);
          const allChildrenChecked = childNodes.every(childNode => existingEntities.includes(childNode.id));
          return { ...node, isChecked: allChildrenChecked };
        }
        else {
          return { ...node, isChecked: existingEntities.includes(node.id) };
        }
      });

    },

    forceSyncByDate() {
      const params = {
        companyId: this.forceSync.companyId,
        subscriptionId: this.userStore.userInfo.subscriptionId,
        forceSyncDate: this.forceSync.type === 'full' ? null : this.forceSync.date
      };
      preLoader.show();
      agent.get<SyncResponse>(`v1/Company/FortnoxSync`, params).then((response) => {
        if (response.result) {
          modal.Close('force-sync-modal');
          toasterService.success(response.result.message);
          preLoader.hide();
        }
      }).catch(() => {
        preLoader.hide();
        modal.Close('force-sync-modal');
      });
    },
    open() {
      this.form = {} as Company;
      this.isUpdate = false;
      this.scopeTreeSelectedNodes = this.treeViewData.filter(
        node =>
          this.defaultTreeViewOptions.find(opt => opt === node.id)
          || this.defaultTreeViewOptions.find(opt => opt === node.pid)
      ).map(node => node.id);

      this.treeViewData = this.treeViewData.map(node => {
        if (this.defaultTreeViewOptions.find(opt => opt === node.id)
          || this.defaultTreeViewOptions.find(opt => opt === node.pid)) {
          return { ...node, isChecked: true };
        }
        else {
          return { ...node, isChecked: false };
        }
      }
      );

      modal.Open('companyModal');
    },
    close() {
      this.form = {} as Company;
      modal.Close('companyModal');
    },
    closeForceSyncModal() {
      modal.Close('force-sync-modal');
    },
    filterCommandsBasedOnModule() {
      if (hasFortnox()) {
        return this.commands;
      }
      return this.commands.filter(c => ['Edit', 'Delete'].includes(c.type));
    },
    checkScopeChanged(existingScope: string, newScope: string) {
      const existingScopes = existingScope.split(' ').map(s => s.toLowerCase());
      const newScopes = newScope.split(' ').map(s => s.toLowerCase());

      if (existingScopes.length !== newScopes.length) {
        return true;
      }

      return !existingScopes.every(s => newScopes.includes(s));
    },
    getFormattedSwedishDatetime(date: string) {
      if (date) {
        const formattedUtcDateStr = date.replace(' ', 'T') + 'Z';

        const utcDate = new Date(formattedUtcDateStr);

        const swedishDate = utcDate.toLocaleString('sv-SE', {
          timeZone: 'Europe/Stockholm'
        });

        return moment(swedishDate).format("YYYY-MM-DD hh:mm A");
      }
      return "";
    }
  }
});
