const en = {
  //Common
  update: "Update",
  save: "Save",
  remove: "Remove",
  addNew: "Add New",
  add: "Add",
  close: "Close",
  actions: "Actions",
  all: "All",
  edit: "Edit",
  delete: "Delete",
  selectAll: "Select All",
  swedish: "Swedish",
  english: "English",
  yes: "Yes",
  no: "No",
  clear: "Clear",
  new: "New",
  msgSureWantToDelete: "Are you sure want to delete?",
  msgSureWantToMapAllCompany: "Are you sure want to map all company?",
  msgSureWantToMap: "Are you sure want to map?",
  msgAllCompany: "All Company",
  msgOnlyThisCompany: "Only This Company",
  msgSureWantToDeleteRole:
    "The role is being used by user(s). Are you sure want to delete?",
  msgSave: "Record has been saved successfully!",
  msgUpdate: "Record has been updated successfully!",
  msgDelete: "Record has been removed successfully!",
  msgUnauthorized: "You're Unauthorized! Please contract to the administrator.",
  msgForbidden: "Forbidden!!",
  msgNotFound: "Not Found!!",
  msgServerError: "Server Error!!",
  msgRecoveryKeyNotFound: "Invalid Recovery Key!",
  deleteConfiramtion: "Delete Confirmation",
  mapConfirmation: "Map Confirmation",
  host: "Host",
  keepSingle: "Keep Single",
  msgRecoverPasswordSuccessfully: "Password recovered successfully!",
  cancel: "Cancel",
  signOut: "Sign Out",
  create: "Create",
  msgSelectForecastVersion: "Please select forecast version",
  copyFrom: "Copy From",
  isBudget: "Is Budget",
  msgConfirmation: "Confirmation",
  sourceConfirmation: "Source Confirmation",
  msgSureWantToChangeSource: "Are you sure want to change source?",
  msgThisCompany: "Apply for this company",
  msgApplyAllCompany: "Apply for all company",
  msgSelectUserType: "Please select user type",
  dbuserid: "DB User Id",
  allowedIPs: "Allowed IPs",
  username: "Username",

  //Change Password
  changePassword: "Change Password",
  oldPassword: "Old Password",
  newPassword: "New Password",
  confirmPassword: "Confirm Password",
  change: "Change",
  logOut: "Log Out",
  myProfile: "My Profile",

  //Home
  home: "Home",

  //Module
  module: "Module",
  name: "Name",
  displayName: "Display Name",
  active: "Active",
  displayNameRequired: "Display Name Required!",
  selectModule: "Select a Module",

  //Customer
  customer: "Customer",
  organization: "Organization",
  server: "Server",
  database: "Database",
  subscriber: "Subscriber",
  type: "Type",
  first: "First",
  last: "Last",
  email: "Email",
  password: "Password",
  retype: "Retype",
  use: "Use",
  internal: "Internal",
  external: "External",
  user: "User",
  test: "Test",
  connection: "Connection",
  register: "Register",
  loading: "Loading",
  modules: "Modules",
  subscriptionDeleteConfirm:
    "Delete also database related to this subscription",
  msgUnableHostDelete: "Unable Host Delete!",
  msgOrganizationNameNotGood: "Please enter a valid organization name!",
  msgPasswordConfirmPassword: "Password and confirm password aren't match!",
  msgSelectModuleAtLeast: "Please select at least one module!",
  msgSelectDatabase: "Please select database!",
  msgGiveServer: "Please give server name!",
  msgGiveDBUserName: "Please give database user name!",
  msgGiveDBUserPassword: "Please give database user password!",
  msgSelectCustomer: "Please select customer",
  msgModuleError: "Please correct module errors",
  fetchDatabases: "Fetch Databases",
  msgSelectResponsible: "Please select responsible..",
  customerResponsible: "Customer Responsible",
  msgCustomerNumberExists: "Customer number already exists",
  msgRenameSchema:
    "After renaming the module schema, you will no longer be able to use the old schema.",
  manageCustomerGroup: "Manage Customer Group",
  backCustomer: "Back Customer",
  customerGroup: "Customer Group",
  databaseUser: "Database User",
  entitySetup: "Entity Setup",

  //Page
  page: "Page",
  title: "Title",
  url: "Url",
  icon: "Icon",
  parent: "Parent",
  ordinal: "Ordinal",
  msgSelectModule: "Please select module",
  msgSelectSubscriberType: "Please select subscriber type",
  msgSelectParent: "Please select parent",
  settings: "Settings",

  //Permission
  permission: "Permission",
  role: "Role",
  msgGiveRole: "Please give role",
  msgSelectOnePageAtLeast: "Please select at least one page",

  //User
  subscription: "Subscription",
  msgSelectRole: "Please select role",
  msgSelectSubscription: "Please select subscription",
  enableRenewTokenErrorAlert:'Enable Renew Token Error Alert',
  //Cost Center
  costCenter: "Cost Center",
  code: "Code",
  note: "Note",

  //Cost Center Permission
  scope: "Scope",
  scopes: "Scopes",
  fullName: "Full Name",
  msgSelectPermissionScope: "Please select permission scope",
  msgSelectUser: "Please select user",
  msgSelectCostCenterAtLeast: "Please select at least one cost center",
  allPermissionScope: "All Permission Scope",

  //Result Sheet or Balance Sheet (Financial Reporting)
  financial: "Financial",
  reporting: "Reporting",
  account: "Account",
  mapping: "Mapping",
  balance: "Balance",
  sheet: "Sheet",
  design: "Design",
  back: "Back",
  range: "Range",
  manage: "Manage",
  select: "Select",
  default: "Default",
  start: "Start",
  end: "End",
  specific: "Specific",
  row: "Row",
  costCenterLevel: "Cost Center Level",
  msgSureDeleteHeaderSubHeader:
    "Are you sure delete this header with all sub headers?",
  msgSureDeleteSubHeader: "Are you sure delete this sub header?",
  msgSureDeleteResultSheet: "Are you sure want to delete result sheet",
  msgSureDuplicateResultSheet: "Are you sure want to duplicate result sheet",
  msgSureDeleteBalanceSheet: "Are you sure want to delete balance sheet",
  msgSureDuplicateBalanceSheet: "Are you sure want to duplicate balance sheet",

  //Account Group
  declaration: "Declaration",
  group: "Group",
  sign: "Sign",
  msgSelectAccountGroup: "Please select account group",
  msgSelectSource: "Please select source",
  msgSelectCostCenter: "Please select cost center",
  msgSelectCostCenterLevel: "Please select cost center level",
  addSpecificSubHeader: "Add Specific Header",
  accountGroupName: "Account Group Name",
  accountGroups: "Account Groups",
  accountGroup: "Account Group",
  isInternal: "Is Internal",
  isRevenue: "Is Revenue",
  msgAllAccountGroup: "All Account Group",

  //Source
  source: "Source",
  msgGiveSource: "Please give source",

  //Result Sheet
  resultSheet: "Result Sheet",
  manageResultSheet: "Manage Result Sheet",
  addNewRow: "Add New Row",
  header: "Header",
  subHeader: "Sub Header",
  bold: "Bold",
  extraBold: "Extra Bold",
  showSubHeader: "Show Sub Header",
  showHeader: "Show Header",
  isPercentage: "Is Percentage",
  resultSheetDesignRow: "Result Sheet Design Row",
  rowName: "Row Name",
  msgSelectResultSheet: "Please select result sheet",
  msgResultSheetNotSelected: "No result sheet selected",
  msgSelectHeader: "Please select header",
  msgSelectSubHeader: "Please select sub header",
  msgSelectSpecificSubHeader: "Please select specific sub header",
  msgGiveDefaultSubHeader: "Please give default sub header",
  msgSelectDefaultSubHeader: "Please select default sub header",
  msgGiveSign: "Please give sign",
  isProfitLevel: "Profit Level",
  isProfitLevelPercentage: "Profit Level(%)",
  isDefault: "Main",
  msgDefaultResultSheetRequiredForCreateForecast:
    "Default Result Sheet required for creating a forecast",

  //Balance Sheet
  msgBalanceSheetNotSelected: "No balance sheet selected",
  balanceSheetDesignRow: "Balance Sheet Design Row",
  manageBalanceSheet: "Manage Balance Sheet",
  msgSelectBalanceSheet: "Please select balance sheet",

  //Balance Sheet Mapping
  msgSureDeleteBalanceSheetMapping:
    "Are you sure want to delete balance sheet mapping?",

  //////Fortnox

  //Company
  msgSureAllDataWithCompany:
    "All data related to this company will be deleted. Are you sure want to delete?",
  creationTime: "Creation Time",
  refreshTokenExpiryDate: "RefreshToken Expire Date",
  lastSyncedDate: "Last Synced Date",
  msgSelectCompany: "Please select company",
  scopeChangeApplied: "Scope Change Applied",
  orderOrCustomerInvoices: "Order or Customer invoices",
  bookkipingOrCustomerInvoices: "Bookkeeping or customer invoices",
  bookkipingOrOrderOrCustomerInvoices:
    "Bookkeeping or Order or Customer invoices",
  any: "Any",
  bookkiping: "Bookkeeping",
  order: "Order",
  salary: "Salary",
  facilityRegister: "Facility register",
  share: "Share",

  //Customer
  companyId: "Company Id",
  address1: "Address1",
  address2: "Address2",
  city: "City",
  customerNumber: "Customer Number",
  organisationNumber: "Organisation Number",
  phone: "Phone",
  zipCode: "Zip Code",

  //Account
  companyName: "Company Name",
  description: "Description",
  number: "Number",
  sRU: "SRU",
  year: "Year",
  vATCode: "VAT Code",
  balanceBroughtForward: "Balance Brought Forward",
  balanceCarriedForward: "Balance Carried Forward",
  syncDate: "Sync Date",
  rangeStart: "Range Start",
  rangeEnd: "Range End",
  find: "Find",
  msgAllYear: "All Year",

  //Account Chart

  //Financial Year
  id: "Id",
  fromDate: "From Date",
  toDate: "To Date",
  accountCharts: "Account Charts",
  accountingMethod: "Accounting Method",

  //Project
  projectNumber: "Project Number",
  startDate: "Start Date",
  endDate: "End Date",
  status: "Status",
  isDeleted: "Is Deleted",

  //SyncLog
  masterLogId: "Master Log Id",
  requestedBy: "Requested By",
  requestedAt: "Requested At",
  startedAt: "Started At",
  runStatus: "Run Status",
  hasError: "Has Error",
  errorMessage: "Error Message",
  isFullSync: "Full Sync",
  jobRunId: "Job Run Id",
  tryCount: "Try Count",
  syncLogDetails: "Sync Log Details",
  logId: "Log Id",
  completedAt: "Completed At",
  entity: "Entity",
  copy: "Copy",
  completedWithErrors: "Completed With Errors",
  completed: "Completed",
  pending: "Pending",
  failed: "Failed",
  running: "Running",
  msgCopied: "Copied to clipboard",

  //CompanySettings
  organizationNumber: "Organization Number",
  address: "Address",
  bG: "BG",
  bIC: "BIC",
  branchCode: "Branch Code",
  contactFirstName: "Contact First Name",
  contactLastName: "Contact Last Name",
  country: "Country",
  countryCode: "Country Code",
  databaseNumber: "Database Number",
  domicile: "Domicile",
  fax: "Fax",
  iBAN: "IBAN",
  pG: "PG",
  phone1: "Phone1",
  phone2: "Phone2",
  taxEnabled: "Tax Enabled",
  vATNumber: "VATNumber",
  visitAddress: "Visit Address",
  visitCity: "Visit City",
  visitCountry: "Visit Country",
  visitCountryCode: "Visit Country Code",
  visitName: "Visit Name",
  visitZipCode: "Visit Zip Code",
  website: "Website",

  //Invoice
  booked: "Booked",
  cancelled: "Cancelled",
  currency: "Currency",
  currencyRate: "Currency Rate",
  currencyUnit: "Currency Unit",
  customerName: "Customer Name",
  documentNumber: "Document Number",
  dueDate: "Due Date",
  externalInvoiceReference1: "External Invoice Reference1",
  externalInvoiceReference2: "External Invoice Reference2",
  invoiceDate: "Invoice Date",
  invoiceType: "Invoice Type",
  noxFinans: "Nox Finans",
  oCR: "OCR",
  voucherNumber: "Voucher Number",
  voucherSeries: "Voucher Series",
  voucherYear: "Voucher Year",
  wayOfDelivery: "Way Of Delivery",
  termsOfPayment: "Terms Of Payment",
  sent: "Sent",
  total: "Total",
  finalPayDate: "Final Pay Date",
  language: "Language",
  lastRemindDate: "Last Remind Date",
  net: "Net",
  notCompleted: "Not Completed",
  offerReference: "Offer Reference",
  orderReference: "Order Reference",
  ourReference: "Our Reference",
  paymentWay: "Payment Way",
  priceList: "Price List",
  printTemplate: "Print Template",
  remarks: "Remarks",
  reminders: "Reminders",
  roundOff: "Round Off",
  taxReduction: "Tax Reduction",
  termsOfDelivery: "Terms Of Delivery",
  totalVAT: "Total VAT",
  vATIncluded: "VAT Included",
  yourOrderNumber: "Your Order Number",
  yourReference: "Your Reference",
  taxReductionType: "Tax Reduction Type",
  isSyncRows: "Sync Rows",
  invoiceDetails: "Invoice Details",

  //Invoice Accural
  invoiceNumber: "Invoice Number",
  period: "Period",
  uRL: "URL",

  //Invoice Payment
  amount: "Amount",
  writeOffExist: "Write Off Exist",
  paymentDate: "Payment Date",

  //Supplier Invoice
  administrationFee: "Administration Fee",
  comments: "Comments",
  credit: "Credit",
  creditReference: "Credit Reference",
  disablePaymentFile: "Disable Payment File",
  externalInvoiceNumber: "External Invoice Number",
  externalInvoiceSeries: "External Invoice Series",
  freight: "Freight",
  givenNumber: "Given Number",
  paymentPending: "Payment Pending",
  roundOffValue: "Round Off Value",
  supplierNumber: "Supplier Number",
  supplierName: "Supplier Name",
  vAT: "VAT",
  vATType: "VAT Type",
  salesType: "Sales Type",
  creditCurrency: "Credit Currency",
  vat: "VAT",
  ocr: "OCR",
  isSyncedRow: "Synced Row",
  isPaymentPending: "Payment Pending",
  vatType: "VAT Type",
  supplierInvoiceDetails: "Supplier Invoice Details",
  articleNumber: "Article Number",
  itemDescription: "Item Description",
  accountDescription: "Account Description",
  debitCurrency: "Debit Currency",
  transactionInformation: "Transaction Information",
  debit: "Debit",
  price: "Price",
  quantity: "Quantity",
  unit: "Unit",
  stockPointCode: "Stock Point Code",
  stockLocationCode: "Stock Location Code",
  rowIndex: "Row Index",
  rowNumber: "Row Number",
  removed: "Removed",
  supplierGroup: "Supplier Group",
  manageSupplierGroup: "Manage Supplier Group",
  backSupplier: "Back Supplier",

  //Supplier Invoice File Connection
  fileId: "File Id",
  supplierInvoiceNumber: "Supplier Invoice Number",

  //Voucher
  transactionDate: "Transaction Date",
  referenceNumber: "Reference Number",
  referenceType: "Reference Type",
  approvalState: "Approval State",

  //Menu
  costCenterPermission: "Cost Center Permission",
  financialReporting: "Financial Reporting",
  "p&lMapping": "P&L Mapping",
  "p&lDesign": "P&L Design",
  "p&lHeader": "P&L Header",
  balanceSheetDesign: "Balance Sheet Design",
  balanceSheetMapping: "Balance Sheet Mapping",
  fortnox: "Fortnox",
  company: "Company",
  accountChart: "Account Chart",
  financialYear: "Financial Year",
  project: "Project",
  syncLog: "Sync Log",
  companySettings: "Company Settings",
  invoice: "Invoice",
  invoiceAccural: "Invoice Accural",
  invoicePayment: "Invoice Payment",
  supplierInvoice: "Supplier Invoice",
  supplierInvoiceFileConnection: "Supplier Invoice File Connection",
  voucher: "Voucher",
  absenceTransaction: "Absence Transaction",
  articleFileConnection: "Article File Connection",
  assetFileConnection: "Asset File Connection",
  assetType: "Asset Type",
  asset: "Asset",
  attendanceTransaction: "Attendance Transaction",
  contractAccrual: "Contract Accrual",
  contractTemplate: "Contract Template",
  contract: "Contract",
  lockedPeriod: "Locked Period",
  offer: "Offer",
  salaryTransaction: "Salary Transaction",
  scheduleTime: "Schedule Time",
  supplierInvoiceAccrual: "Supplier Invoice Accrual",
  supplierInvoiceExternalUrlConnection:
    "Supplier Invoice External Url Connection",
  supplierInvoicePayment: "Supplier Invoice Payment",

  // VoucherFIleConnection
  voucherFileConnection: "Voucher File Connection",
  voucherDescription: "Voucher Description",
  doSync: "Do Sync",
  enableSyncing: "Enable Syncing",

  // "Account Mapping" :"Account Mapping",
  // "Back Account Mapping" :"Back Account Mapping",
  // "Result Sheet":"Result Sheet",
  // "Balance Sheet":"Balance Sheet",
  // "Back Balance Sheet Mapping" :"Back Balance Sheet Mapping",
  // "Result Sheet Design Row":"Result Sheet Design Row",
  // "Balance Sheet Mapping":"Balance Sheet Mapping",
  // "Balance Sheet Design Row":"Balance Sheet Design Row",
  // "Manage Result Sheet":"Manage Result Sheet",
  //
  // "Manage Source":"Manage Source",
  // "Manage Account Groups":"Manage Account Groups",
  // "Manage Account Range":"Manage Account Range",
  // "Add Account Range":"Add Account Range",

  // "Swedish Name":"Swedish Name",
  // "Declaration":"Declaration",
  // "Actions":"Actions",
  // "Account Group":"Account Group",
  // "English Name":"English Name",

  // "Design":"Design",
  // "No result sheet selected":"No result sheet selected",
  // "Select Result Sheet":"Select Result Sheet",
  // "Select Balance Sheet":"Select Balance Sheet",
  // "Select Balance Sheet Mapping":"Select Balance Sheet Mapping",
  // "Header":"Header",
  // "Sub Header":"Sub Header",
  // "Row Name":"Row Name",
  // "Bold":"Bold",
  // "Extra Bold":"Extra Bold",
  // "Add New Row":"Add New Row",
  // "Show Sub Header":"Show Sub Header",
  // "Is Percentage":"Is Percentage",
  // "Select":"Select",
  // "Source":"Source",

  // "Cost Center Level":"Cost Center Level",
  // "Start":"Start",
  // "End":"End",
  // "Sign":"Sign",
  // "Default Header":"Default Header",
  // "Specific Sub Header":"Specific Sub Header",
  // "Range":"Range",
  // "Add Specific Header":"Add Specific Header",
  // "Close":"Close",
  // "Balance Sub Header":"Balance Sub Header",
  // "Save information successfully":"Save information successfully",
  // "Update information successfully":"Update information successfully",
  // "Delete information successfully":"Delete information successfully",
  // "All (*) marks are required":"All (*) marks are required",
  // "DatabaseNameErrorMessage": "Database Name must be between 1 and 128 characters.",
  // "Customer":"Customer",
  // "User":"User",
  // "Cost Center Permission":"Cost Center Permission",
  // "Fortnox":"Fortnox",
  // "Company":"Company",
  // "Financial Reporting":"Financial Reporting",
  // "P&L Account Mapping":"P&L Account Mapping",
  // "P&L Design":"P&L Design",
  // "Balance Sheet Design":"Balance Sheet Design"

  //Page Build
  pageBuilder: "Page Builder",
  pageBuild: "Page Build",
  pageManage: "Page Manage",
  databaseTableName: "Database Table Name",
  buildForm: "Build Form",
  definition: "Definition",
  input: "Input",
  selectFieldType: "Select Field Type",
  fieldType: "Field Type",
  dropdownRef: "Dropdown Ref",
  selectSchema: "Select Schema",
  selectTable: "Select Table",
  selectColumnId: "Select Column (Id)",
  selectColumnName: "Select Column (Name)",
  hasDatabaseSource: "Has Database Source",
  dropdownData: "Dropdown data",
  selectColumn: "Select Column",
  radioData: "Radio Data",
  checkboxRef: "Checkbox Ref",
  checkboxData: "Checkbox Data",
  defaultDate: "Default Date",
  databaseType: "Database Type",
  decimalPlace: "Decimal Place",
  selectDatabaseType: "Select Database Type",
  size: "Size",
  placeHolder: "Place Holder",
  isMax: "Is Max",
  databaseName: "Database Name",
  isRequired: "Is Required",

  //Page Manage
  importExcel: "Import(.xls/.csv)",
  selectPage: "Select Page",
  ok: "Ok",
  root: "Root",

  pleaseFillAllRequiredFields: "Please fill all required fields",
  dataImportedSuccessfully: "Data imported successfully",

  //Suppliers
  suppliers: "Suppliers",
  companyname: "Company Name",
  bankAccountNumber: "Bank Account Number",
  costcenter: "Cost Center",
  preDefinedAccount: "Pre Defined Account",
  costCenterNumber: "Cost Center Number",
  costCenterName: "Cost Center Name",

  //Data Sync Configuration
  dataSyncConfiguration: "Data Sync Configuration",
  featureName: "Feature Name",
  isSyncFromFortnox: "Is Sync From Fortnox",
  syncFromFortnox: "Sync From Fortnox",
  log: "Log",
  organizationName: "Organization Name",
  msgSelectFeature: "Please select feature",
  isEnableSync: "Is Enable Sync",
  allSync: "All Sync",

  //Property
  property: "Property",
  selectProperty: "Select Property",
  values: "Values",
  msgGivePropertyName: "Please give property name",
  msgGivePropertyValue: "Please give property value",

  // Power Office
  powerOffice: "Power Office",
  applicationKey: "Application Key",
  clientKey: "Client Key",
  subscriptionKey: " Subscription Key",
  shouldSync: "Should Sync",
  notation: "Notation",
  accountNo: "Account No",
  createdDate: "Created Date",
  lastChangedDate: "Modified Date",
  currencyCode: "Currency Code",
  postingDate: "Posting Date",
  voucherDate: "Voucher Date",
  voucherNo: "Voucher No",
  accountTransaction: "Account Transaction",
  client: "Client",
  clientContact: "Client Contact",
  contactGroup: "Contact Group",
  contactPerson: "Contact Person",
  dimension: "Dimension",
  dimensionSetting: "Dimension Setting",
  customerSubledger: "Customer Subledger",
  supplier: "Supplier",
  supplierSubledger: "Supplier Subledger",
  clientName: "Client Name",
  emailAddress: "Email",
  yearOfBirth: "Birth Year",
  legalName: "Legal Name",
  contactCreatedDate: "Contact Created Date",
  customerCreatedDate: "Customer Created Date",
  customerSince: "Customer Since",
  invoiceEmailAddress: "Invoice Email",
  phoneNumber: "Phone Number",
  paymentTermId: "Payment Term ID",
  currencyAmount: "Currency Amount",
  customerAccountNo: "Customer Account No",
  supplierAccountNo: "Supplier Account No",
  invoiceNo: "Invoice No",
  projectCode: "Project Code",
  projectId: "Project ID",
  voucherId: "Voucher ID",
  createdFromImportJournalId: "Import Journal ID",
  externalImportReference: "External Import Reference",
  customerSubLedger: "Customer Sub Ledger",
  journalVoucher: "Journal Voucher",
  departmentCode: "Department Code",
  generalLedgerAccount: "General Ledger Account",

  //Auth Setting
  authSetting: "Auth Setting",
  clientId: "Client Id",
  clientSecret: "Client Secret",

  //CostCenterGroup
  costCenterGroup: "Cost Center Group",
  manageCostCenterGroup: "Manage Cost Center Group",
  backCostCenter: "Back Cost Center",
  msgSelectCostCenterGroup: "Please select cost center group",

  isActive: "Is Active",

  //Unit
  units: "Units",
  date: "Date",
  employeeId: "Employee Id",
  expense: "Expense",
  salaryCode: "Salary Code",
  salaryRow: "Salary Row",
  textRow: "Text row",
  deliveryDate: "Delivery date",
  orderDate: "Order date",
  orderType: "Order type",
  personelType: "Personal type",
  personalIdentityNumber: "Personal Identity number",
  nonRecurringTax: "Non recurring tax",
  monthlySalary: "Monthly salary",
  jobTitle: "Job title",
  inActive: "Inactive",
  foraType: "Fora type",
  fromQuantity: "From quantity",
  hours: "Hours",
  scheduleId: "Schedule Id",
  externalUrlConnection: "External Url Connection",
  employmentForm: "Employment Form",
  employmentDate: "Employment date",
  employedTo: "Employed to",
  clearingNo: "Clearing No",
  bankAccountNo: "Bank account no",
  averageWeeklyHours: "Average weekly hours",
  averageHourlyWage: "Average hourly wage",
  autoNonRecurringTax: "Auto Non Recurring Tax",

  //Budget Forecast
  budgetForecast: "Budget/Forecast",
  msgSelectYear: "Please select year",
  msgSelectVersion: "Please select version",
  version: "Version",
  actualStart: "Actual Start",
  actualEnd: "Actual End",
  forecastStart: "Forecast Start",
  forecastEnd: "Forecast End",
  clearActual: "Clear Actual",
  setAsCurrentDefault: "Set as current default",
  forecast: "Forecast",
  forecastVersion: "Forecast Version",
  msgSelectProject: "Please select project",
  setAsCurrentDefaultForecast: "Set as current default forecast",
  excelExport: "Excel Export",
  msgSelectCustomerResponsible: "Please select customer responsible",
  allProject: "All Project",
  allCustomer: "All Customer",
  msgNoRecordsToDisplay: "No records to display",
  ccGroup: "C.C Group",
  aGroup: "A. Group",
  responsible: "Responsible",
  current: "Current",
  customerForecast: "Customer Forecast",
  enterTextHere: "Enter text here (max:500)",
  submit: "Submit",
  comment: "Comment",
  msgChangesConfirmation: "Changes confirmation",
  msgLeaveForecastChanges:
    "Warning: You have unsaved changes. Are you sure want to continue?",
  msgForecastVersionClose:
    "Actual values of this version will be deleted from the forecast, and the actual values will be updated with actual revenue data. Do you want to continue?",
  projectForecast: "Project Forecast",
  //Employee
  employee: "Employee",
  firstName: "First Name",
  hourlyPay: "Hourly Pay",
  lastName: "Last Name",
  postCode: "Post Code",
  salaryForm: "Salary Form",
  taxAllowance: "Tax Allowance",
  taxColumn: "Tax Column",
  taxTable: "Tax Table",
  bankEmployeeNo: "Bank Employee No",

  //FinancialYear
  msgToDateShouldBeGreater: "To date should be greater than from date",

  //password validator
  msgEnterAPassword: "Enter a password.",
  msgInvalidPassword:
    "Password requires at least one number, one lowercase letter, one uppercase letter, and at least six characters.",

  //App-Log
  logType: "Log Type",
  message: "Message",
  details: "Details",
  subscriptionId: "Subscription Id",
  className: "Class Name",
  methodName: "Method Name",
  timeGenerated: "Time Generated",
  appLog: "App Log",
  requestedUser: "Requested User",

  //Cost Center User
  //costCenterUser: "Cost Center User",
  costCenterUser: "Employee (Manual Entry)",
  msgSelectCostCenterUser: "Please select cost center user",
  or: "Or",
  msgSelectCostCenterUserOrEmployee:
    "Please select cost center user or employee",
  msgSelectEmployee: "Please select employee",

  //Cost center group permission
  costCenterGroupPermission: "Cost Center Group Permission",
  msgSelectCostCenterGroupAtLeast:
    "Please select at least one cost center group",

  detail: "Detail",

  //SyncLogSummary
  syncLogSummary: "Sync Log Summary",
  success: "Success",
  warnings: "Warnings",
  errors: "Errors",
  criticals: "Criticals",
  lastSync: "Last Sync",
};

export default en;
